import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

import { install, ResizeObserver } from 'resize-observer';
import useSound from 'use-sound';

import { SoundTypes } from '@/models/SoundTypes';

import { cursor, focus } from '@/services/CursorFocusService';

if (!window.ResizeObserver) {
  install();
}

const StateTypes = {
  hovered: 'hovered',
  focused: 'focused',
};

const FocusOverlay = ({ globalListener }) => {
  const roCallback = useRef();
  const moCallback = useRef();
  const [clickSoundPlay, { stop: clickSoundStop }] = useSound(
    SoundTypes.click,
    { volume: 0.25 },
  );
  const [hoverSoundPlay, { stop: hoverSoundStop }] = useSound(
    SoundTypes.hover,
    { volume: 0.25 },
  );

  const ro = useRef(
    new ResizeObserver((...args) => {
      roCallback.current?.(...args);
    }),
  );

  const mo = useRef(
    new MutationObserver((...args) => {
      moCallback.current?.(...args);
    }),
  );

  useEffect(
    () => {
      if (!globalListener) {
        return undefined;
      }

      const state = {
        [StateTypes.current]: null,
        [StateTypes.focused]: null,
      };

      roCallback.current = (entries) => {
        entries.forEach((entry) => {
          if (entry.target !== state.current && entry.target !== state.focused) {
            entries.forEach((item) => ro.current.unobserve(item.target));
          } else {
            if (entry.target === state[StateTypes.hovered]) {
              setTimeout(() => {
                cursor.focusOn(state[StateTypes.hovered]);
              }, 100);
            }

            if (entry.target === state[StateTypes.focused]) {
              setTimeout(() => {
                focus.focusOn(state[StateTypes.focused]);
              }, 100);
            }
          }
        });
      };

      moCallback.current = (mutations) => {
        const item = state[StateTypes.hovered];

        if (!item) {
          return;
        }

        mutations.forEach((mutation) => {
          if (!mutation.removedNodes.length) {
            return;
          }

          mutation.removedNodes.forEach((node) => {
            if (node === item || node.contains(item)) {
              ro.current.unobserve(item);
              cursor.focusOn(null);
              state[StateTypes.hovered] = null;
            }
          });
        });
      };

      const onClick = () => {
        clickSoundPlay();
      };

      const onDown = () => {
        cursor.press();
      };

      const onUp = () => {
        cursor.release();
      };

      const onEnter = (event) => {
        if (event?.target?.tabIndex < 0 || event.target === document) {
          return;
        }

        hoverSoundStop();
        clickSoundStop();

        let item = state[StateTypes.hovered];

        if (item) {
          item.removeEventListener('pointerdown', onDown, true);
          item.removeEventListener('click', onClick, true);
          ro.current.unobserve(item);
          mo.current.disconnect();
        }

        if (item !== event.target) {
          item = event.target;
          cursor.focusOn(item);
        }

        if (item) {
          hoverSoundPlay();
          ro.current.observe(item);
          item.addEventListener('pointerdown', onDown, true);
          item.addEventListener('click', onClick, true);
          mo.current.observe(document.body, { childList: true, subtree: true });
        }

        state[StateTypes.hovered] = item;
      };

      const onFocus = (event) => {
        if (event?.target?.tabIndex < 0 || event.target === document) {
          return;
        }

        hoverSoundStop();
        hoverSoundPlay();

        let item = state[StateTypes.focused];

        if (item) {
          ro.current.unobserve(item);
        }

        if (item !== event.target) {
          item = event.target;
          setTimeout(() => {
            focus.focusOn(item);
          }, 10);
        }

        ro.current.observe(item);

        state[StateTypes.focused] = item;
      };

      const onLeave = (event) => {
        let item = state[StateTypes.hovered];

        if (event?.target === item) {
          hoverSoundStop();

          if (item) {
            mo.current.disconnect();
            ro.current.unobserve(item);
            item.removeEventListener('pointerdown', onDown, true);
            item.removeEventListener('click', onClick, true);
          }

          item = item?.parentNode;
          while (item && item.tabIndex < 0) {
            item = item.parentNode;
          }

          if (item === document) {
            item = null;
          }

          cursor.focusOn(item);
        }

        state[StateTypes.hovered] = item;
      };

      const onBlur = () => {
        let item = state[StateTypes.focused];

        if (item) {
          ro.current.unobserve(item);
        }

        item = null;
        focus.focusOn();
        focus.hide();

        state[StateTypes.focused] = item;
      };

      document.addEventListener('focus', onFocus, true);
      document.addEventListener('blur', onBlur, true);
      document.addEventListener('pointerenter', onEnter, true);
      document.addEventListener('pointerleave', onLeave, true);
      document.addEventListener('pointerup', onUp, true);

      return () => {
        mo.current.disconnect();
        roCallback.current = null;
        moCallback.current = null;

        if (state[StateTypes.hovered]) {
          state[StateTypes.hovered].removeEventListener(
            'pointerdown',
            onDown,
            true,
          );
        }

        document.removeEventListener('focus', onFocus, true);
        document.removeEventListener('blur', onBlur, true);
        document.removeEventListener('pointerenter', onEnter, true);
        document.removeEventListener('pointerleave', onLeave, true);
        document.removeEventListener('pointerup', onUp, true);
      };
    },
    [
      clickSoundPlay,
      clickSoundStop,
      globalListener,
      hoverSoundPlay,
      hoverSoundStop,
    ],
  );

  useEffect(
    () => {
      let isEnter = true;
      const onDocumentEnter = () => {
        if (!isEnter) {
          cursor.show();
          isEnter = true;
        }
      };
      const onDocumentLeave = () => {
        if (isEnter && !cursor.focusOn()) {
          cursor.hide();
          isEnter = false;
        }
      };

      document.documentElement?.addEventListener(
        'pointerenter',
        onDocumentEnter,
        true,
      );
      document.documentElement?.addEventListener('pointerleave', onDocumentLeave);

      return () => {
        document.documentElement?.removeEventListener(
          'pointerenter',
          onDocumentEnter,
          true,
        );
        document.documentElement?.removeEventListener(
          'pointerleave',
          onDocumentLeave,
        );
      };
    },
    [],
  );

  return null;
};

FocusOverlay.propTypes = {
  globalListener: PropTypes.bool,
};

FocusOverlay.defaultProps = {
  globalListener: true,
};

export default FocusOverlay;
