import blue from '@mui/material/colors/blue';

const theme = {
  name: 'dark',
  palette: {
    mode: 'dark',
    primary: {
      main: blue[200],
    },
    background: {
      default: '#282C34',
      paper: '#21252B',

      // default: '#1d283c',
      // paper: '#2B4055',

      // default: '#151D2B',
      // paper: '#223344',
    },
  },
};

export default theme;
