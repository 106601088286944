import dark from '@/themes/dark';

const theme = {
  ...dark,

  name: 'light',
  palette: {
    type: 'light',
  },
};

export default theme;
